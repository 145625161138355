<template>
  <div class="notif-list d-flex flex-column">
    <v-card
      v-if="!notifs.length && !processingQuery"
      elevation="0"
    >
      <v-card-text class="nowrap">
        {{$t('t.ThereIsNothingToSeeHere')}}
      </v-card-text>
    </v-card>
    <v-btn
      v-else
      class="text-body-2"
      block
      depressed
      @click="markAllAsRead"
    >{{$t('t.MarkAllAsRead')}}</v-btn>
    <div class="content">
      <v-list
        :key="date"
        dense
        v-for="(entries, date, index) in groupedNotif"
      >
        <v-subheader class="nowrap ml-3">
          <span class="text-body-1 font-weight-medium text--primary">{{date}}</span>
          <v-spacer v-if="canOpenSidePanel && !index" />
          <v-btn
            v-if="canOpenSidePanel && !index"
            @click="openInSidePanel"
            icon
            small
            class="background-plain ml-2"
            elevation=0
          >
            <v-icon small>{{$icon('i.OpenInNew')}}</v-icon>
          </v-btn>
        </v-subheader>
        <notif-line
          class="nowrap"
          :key="index"
          v-for="(entry, index) in entries"
          :item="entry"
          @setAsRead="setAsRead"
        />
      </v-list>
      <v-btn
        v-if="nextPage && !processingQuery"
        @click.stop="query(nextPage)"
        block
        elevation="0"
      >
        {{$t('t.More')}}...
      </v-btn>
    </div>
  </div>
</template>

<script>
import { refreshCache } from '@/wasm/pkg'
export default {
  components: {
    NotifLine: () => import('./notif-line')
  },
  data () {
    return {
      http: this.$http().debounced(),
      markAllAsRefLoading: false,
      nextPage: null,
      notifs: [],
      processingQuery: false,
      unread: false
    }
  },
  computed: {
    groupedNotif () {
      return this.lodash.groupBy(this.notifs, n => {
        const day = this.moment().startOf('day').diff(this.moment(n.date).startOf('day'), 'day')
        switch (day) {
          case 0: return this.$t('t.Today')
          case 1: return this.$t('t.Yesterday')
          default: return this.$t('t.Earlier')
        }
      })
    }
  },
  methods: {
    async markAllAsRead () {
      if (!this.markAllAsRefLoading) {
        this.markAllAsRefLoading = true
        try {
          await this.http.post('/core/v6/notif/read-all')
          refreshCache()
        } catch (e) {
          if (e !== 'Debounced') {
            throw e
          }
        } finally {
          this.markAllAsRefLoading = false
        }
      }
    },
    async query () {
      try {
        this.processingQuery = true

        const response = await this.http.get('/core/v6/notif/list', { params: { nextPage: this.nextPage } })
        this.unread = response.data.unread
        this.nextPage = response.data.nextPage

        response.data.items.forEach(e => {
          this.notifs.push(e)
        })
      } catch (e) {
        if (e !== 'Debounced') {
          throw e
        }
      } finally {
        this.processingQuery = false
      }
    },
    setAsRead (item) {
      item.unread = false
    }
  },
  mounted () {
    this.query()
  },
  props: {
    canOpenSidePanel: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.content
  max-height 70vh
  overflow-y auto
</style>
